'use client';

import { HomePageModel } from "@/app/page";
import { componentMapping } from "@/lib/models/ContenfulSections";
import { ContentfulLivePreview } from "@contentful/live-preview";
import { useContentfulLiveUpdates } from "@contentful/live-preview/react";

const HomePageComponent: React.FC<HomePageModel> = (homePageModel) => {
    const updatedHomePageModel = useContentfulLiveUpdates(homePageModel);

    return (
        <>
            {updatedHomePageModel.sectionsCollection && updatedHomePageModel.sectionsCollection.items && (
                updatedHomePageModel.sectionsCollection.items.map((sectionModel, index) => {
                    if (sectionModel == null) {
                        return null;
                    }
                    const SectionComponent = componentMapping[sectionModel.__typename];
                    return SectionComponent ?
                        <SectionComponent
                            key={sectionModel.__typename + index} {...sectionModel} /> : null;
                })
            )}

        </>

    );
};

export default HomePageComponent;